<template>
  <div style="padding: 20px 60px;">
    <div class="pad-tb20" style="width: 400px;">
      <el-steps :active="step" simple class="mg-b20">
        <el-step title="验证邮箱号码" icon="el-icon-edit"></el-step>
        <el-step title="重置密码" icon="el-icon-setting"></el-step>
      </el-steps>
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="100px"
               input-width="400px">
        <template v-if="step === 1">
          <el-form-item prop="email" label="邮箱">
            <el-input placeholder="邮箱" v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="phoneCode">
            <el-input placeholder="请输入验证码" v-model="ruleForm.phoneCode">
              <el-button @click="sendPhoneCode()" :disabled="phoneCodeCountDown !== 0"
                         slot="append">
                {{ phoneCodeText }}
              </el-button>
            </el-input>
            <div class="captcha"></div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="checkPhone('ruleForm')">下一步</el-button>
          </el-form-item>
        </template>
        <template v-if="step === 2">
          <el-form-item prop="newPassword" label="新密码">
            <el-input type="password" placeholder="密码长度在6~20位之间"
                      v-model="ruleForm.newPassword"></el-input>
          </el-form-item>
          <el-form-item prop="repeatPassword" label="重复密码">
            <el-input type="password" placeholder="密码长度在6~20位之间"
                      v-model="ruleForm.repeatPassword"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="postForgetPwd('ruleForm')">下一步</el-button>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <div style="border-top: 1px #aaaaaa dashed;padding: 15px;">
      <div class="mg-b10">找回密码遇到问题吗？</div>
      <div>1、无法收到短信或邮件，请检查短信收发是否正常或垃圾邮件</div>
      <div>2、请确信账号绑定了邮箱并进行了认证</div>
      <div>3、如果仍有问题，请直接给我们意见反馈</div>
    </div>
  </div>
</template>

<script>
import {captcha} from "@/apis/index";
import validator from "@/resources/js/async-validator";
import $ from 'jquery'

require('@ASSET/libs/jigsaw/jigsaw.min')
export default {
  name: 'forgetPwdByEmail',
  components: {},
  data() {
    return {
      step: 1,
      phoneCodeCountDown: 0,
      phoneCodeText: '发送验证码',
      ruleForm: {
        email: null,
        phoneCode: null,
        newPassword: null,
        repeatPassword: null
      },
      rules: {
        email: [
          {required: true, message: '邮箱不能为空', trigger: 'blur'},
          {trigger: 'blur', validator: validator.validEmail, message: '邮箱格式不正确'}
        ],
        phoneCode: [
          {required: true, message: '验证码不能为空', trigger: 'blur'},
          {len: 6, message: '验证码长度有误', trigger: 'blur'}
        ],
        newPassword: [
          {required: true, message: '密码不能为空', trigger: 'blur'},
          {min: 6, max: 20, message: '密码长度在6~20位之间', trigger: 'blur'}
        ],
        repeatPassword: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => value === this.ruleForm.newPassword ? callback()
                : callback(new Error('密码不一致'))
          }
        ]
      }
    }
  },
  methods: {
    captcha() {
      return new Promise(resolve => {
        window.jigsaw.init({
          el: $('.captcha')[0],
          onSuccess: () => {
            $('.captcha').html('')
            resolve(true)
          }
        })
      })
    },
    countDownPhoneCode(count) {
      if (count <= 0) {
        this.phoneCodeCountDown = 0
        this.phoneCodeText = '发送验证码'
        return
      }
      this.phoneCodeCountDown = count - 1
      this.phoneCodeText = `${this.phoneCodeCountDown}秒后重试`
      setTimeout(() => {
        this.countDownPhoneCode(this.phoneCodeCountDown)
      }, 1000)
    },
    sendPhoneCode() {
      this.$refs['ruleForm'].validateField('email', (msg) => {
        if (!msg) {
          this.captcha().then(res => {
            if (res) {
              this.countDownPhoneCode(60)
              captcha.sendEmailCode({
                email: this.ruleForm.email,
                checkExist: true,
                type: 'FORGET_PASSWORD'
              }).then(res => {
                this.$message.success('验证码发送成功，请注意查收!')
              }).catch(res => {
                this.countDownPhoneCode(0)
              })
            }
          })

        }
      });
    },
    checkPhone(formName) {
      let {email, phoneCode} = this.ruleForm;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          captcha.checkEmailCode({
            email: email,
            code: phoneCode,
          }).then(res => {
            this.step++;
          });
        }
      })
    },
    postForgetPwd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request.post({
            reqcode: '1224',
            reqdesc: '忘记密码',
            ...this.ruleForm
          }).then(res => {
            this.$message.success('密码修改成功')
            this.$router.push('/login')
          })
        } else {
          this.$message.warning('表单填写有误')
        }
      })
    }
  }
}
</script>

<style scoped>
.captcha {
  position: absolute !important;
  z-index: 1;
  background: #FFF;
  right: 0;
}
</style>
